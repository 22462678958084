import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const LaunchBtn = styled(Button)({
  height: "48px",
  lineHeight: "48px",
  maxHeight: "48px",
  width: "144px",
  color: "#B30DA4",
  fontSize: 16,
  fontFamily: "Gilroy-Bold",
  textTransform: "initial",
  background: "rgba(255, 0, 229, 0.2)",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#FF00E5",
    color: "#B30DA4",
  },
});
